import React from "react";
import { HomeBanner, Hosted } from "../../assets";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import { FaBeer } from 'react-icons/fa';
import { Textarea } from "atomize";

const About = () => {
  return (
    <div>
   
      <div className="containe text-center bg-themebg pt-0">
        <div className="container">
          <div className="section-padding">
          <h1 class="home-text py-4">
            Send Us <span class="span-color">Mail</span>
          </h1>
            {/* <p class="hone-p">
              One can experience the complete new, flexible Hosted PBX set up
              you may be familiar by the name of Cloud PBX
            </p> */}
          </div>
        </div>
      </div>

      <div className="section-padding">
        <div className="container text-center pt-4">
 

          <div className="row ani" style={{ textAlign: "initial" }}>
            <div className="col-md-8">
              <div className="row mt-5">
                <div className="col-md-6">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>First name <span>*</span></Form.Label>
                      <Form.Control type="Text" placeholder="Enter First Name" required />
                    </Form.Group>
                  </Form>
                </div>
                <div className="col-md-6">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Last Name <span>*</span></Form.Label>
                      <Form.Control type="email" placeholder="Enter Last Name" required />
                    </Form.Group>
                  </Form>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-6">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Email Id <span>*</span></Form.Label>
                      <Form.Control type="email" placeholder="Enter email" required/>
                    </Form.Group>
                  </Form>
                </div>
                <div className="col-md-6">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Phone Number <span>*</span></Form.Label>
                      <input type="number" onKeyPress="if(this.value.length==10) return false;" />
                      <Form.Control type="number" placeholder="Enter Phone Number" onKeyPress="if(this.value.length==13)" maxlength="13" required/>
                    </Form.Group>
                  </Form>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Company Address </Form.Label>
                      <Form.Control type="text" placeholder="Enter Company Address" />
                    </Form.Group>
                  </Form>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-md-12">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Label>Text Area <span>*</span></Form.Label>
                      <Textarea
                        type="text-area"
                        placeholder="Message"
                        className="height w-100 p-3"
                        
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>

              <Button variant="contained" color="secondary">
                Submit
              </Button>
            </div>

            <div className="col-md-4 bg-contact py-4" style={{marginTop:"50px"}}>
            <div className="p-3 mt-2">
              <h2 className="bold text-white">Address :</h2>
              <p className="text-white f-18">18 Johnson Street Worcester, MA 01604</p>
            </div>

            <div className="p-3 mt-2">
              <h2 className="bold text-white">Contact :</h2>
              <p className="text-white mt-3 col-12 f-18">Our Support Hotline is available 24 Hours a day:<b className="f-18"><a href="tel:+1 (210) 888-9227" className="text-white"> +1 (210) 888-9227 </a></b> Office Open: 24x7</p>
            </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default About;
