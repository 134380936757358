import React from "react";
import { Outbond_Call, cc1, Pict } from "../../assets";

const Internetfax = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Internet <span class="span-color"> Fax </span>
            </h1>
            <p class="hone-p">
            It is completely secure as the entire message is encrypted and only be read by the person for whom it was meant

            </p>
          </div>
        </div>
      </div>

      <div className="container ">
        <div className="section-padding">
        <div className="  ">
          <h1 className="home-text py-2">
           About Internet
            <span class="span-color"> Fax </span>
          </h1>

          <p className="f-17">

            Even in this golden era of communication, Fax is still the most
            useful medium of communication in Business however it also depends
            from business to business as well As we know that every coin has two
            sides same is the case with Fax as well, it also some limitations to
            it like physical movement and it is also not ecofriendly because of
            the use of the paper, inconvenience while sending to multiple users
            To make it better Digitorzo has come with the option of
            Internet Fax as it helps to get the faxes moved into the email and
            like email one can access the same from anywhere and anytime which
            means that you will be required to be in front of the machine and it
            also comes along with other benefits as well
          </p>
          </div>
          <ul className="p-0 mt-5">
            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp;No need of physical Fax Machine – yes it is true to say,
              that Fax Machine will definitely consume space hence with trait of
              Internet Fax all you need to have a device from where you can send
              and receive email, in the similar way one will be able to receive
              ad send the Fax through PDF file
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; Can be accessed from anywhere – Internet Fax is cloud
              service by Digitorzo which can be used through
              smartphone, tablet or computer, all what you have to consider is
              that device should be able to support PDF file format and hence
              one will be able to make the most of it
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; One can opt for Climate Friendly – in today's time, it has
              become the need of an hour to focus on technologies which are safe
              for the climate, Internet Fax also falls under the technology
              which is paperless, hence it helps us to achieve green computing
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; Delivery Failure - with the fax machine, if the machine is
              buzzy, one will get the message of deliver failure however with
              Internet Fax one will not be come across the same
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; No need to install any other software – Internet Fax comes
              with Internet Fax hence one will be not be required to get any
              other software
            </li>
          </ul>
          <br />
        </div>


      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
        <div className="mt-3">
          <h1 className="home-text py-2">
            Updated Features of
            <span class="span-color"> Internet Fax </span>
          </h1>

          <p className="f-17">
            technology has become the part and parcel of our life, if we do not
            adapt the technology, we will be considered outdated and will also
            loose on the opportunity, some of the benefits are as following
          </p>
          <ul className="p-0 mt-4">
            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; Fax to Numerous Receivers – just like email with the help
              of Internet Fax one will be able to send the Fax to multiple users
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp;Delivery notification – once you send the email for the
              Internet Fax, you will in return get the notification whether the
              Fax has been received or not at the receiver end
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; Security – it is completely secure and safe over the cloud
              of Digitorzo which is completely encrypted which will
              only be read by the person for whom it is meant to be Why Choose
              the Digitorzo for Internet Fax- Internet Fax is a boon
              for the organization at this point in time, however with Digitorzo
              Networks LLC it adds up to the smooth functioning of the
              organization
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; Through the use of same device one can send emails, Fax and
              voice communication as we know that how important is the
              communication for any business
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; One will be able to get the notification about the delivery
              of the email if the same has been received or not
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp;You can take the help of the professionals in case one comes
              across some concern with paying any extra amount
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; can access the Fax on Smartphone, tablet or computer
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; Keeping in mind the need of the climate, one can take one
              step ahead towards Green Technology
            </li>

            <li>

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; It is completely secure as the entire message is encrypted
              and only be read by the person for whom it was meant
            </li>
          </ul>
          <br />
        </div>
        </div>
      </section>

    </div>
  );
};
export default Internetfax;
