import React, { useState, Fragment, useEffect } from "react";
import "./sidebar.css";
import "./App.css";
import { useLocation } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import NavSidebar from "./components/Sidebar/Sidebar";
import { StyleReset } from "atomize";
import AppFooter from "./components/footer/AppFooter";
import Routing from "./router";
import { useMediaQuery } from "react-responsive";
import Aside from "./components/Aside";

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
  const handleToggle = () => {
    setToggled(prev => !prev);
  };

  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <>
      <Fragment>
        <StyleReset />
        {isTabletOrMobile ? (
          <Aside
            collapsed={collapsed}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleToggle={handleToggle}
          />
        ) : (
          ""
        )}
        <Navbar
          isMobile={isTabletOrMobile}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
        />
        {/* {!isTabletOrMobile? <Navbar />:
        <NavSidebar />} */}
        <Routing />
        <AppFooter />
      </Fragment>
    </>
  );
}

export default App;
