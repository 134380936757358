import React from "react";
import {
  HomeBanner,
  clients,
  clients1,
  clients2,
  clients3,
  clients4,
  Phonebook,
  Group,
  Call,
  Mike,
  Img2,
  Img1,
  Rectangle,
  img3,
  Voip,
  Virtual,
  tollfreenumber,
  callcenter,
} from "../../assets";
// import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

const Home = () => {
  const classes = useStyles();
  return (
    <div>
      <div className="homebanner">
        <div className="container text-center pt-5">
          <div className="section-padding">
            {/* <p className="home-ptp"> Digitorzo</p> */}
            <h1 className="home-text py-2">
              We Are prominent <span className="span-color">VoIP </span>
              Provider!
            </h1>
            <p className="hone-p">
              Digitorzo is prominent for providing sterling cloud
              based services to all emerging businesses.
            </p>
          </div>
          <img src={HomeBanner} alt="banner" />
        </div>
      </div>

      <div className="section-padding mt-3" id="text">
        <div className="container text-center">
          <div className="row">
            <h2>
              TRUSTED BY OVER 6000+ <span>COMPANIES</span>
            </h2>
          </div>
          <div className="row">
            <div className="col-md-3 col-6 mt-3">
              <img src={clients} alt="client" style={{maxWidth:"100%"}}/>
            </div>
            <div className="col-md-3 col-6 mt-3">
              <img src={clients1} style={{maxWidth:"100%"}}/>
            </div>
            <div className="col-md-3 col-6 mt-3">
              <img src={clients2} style={{maxWidth:"100%"}}/>
            </div>
            <div className="col-md-3 col-6 mt-3">
              <img src={clients3} style={{maxWidth:"100%"}}/>
            </div>
          
          </div>
        </div>
      </div>

      <div className="section-padding">
        <div className="container text-center">
          <div className="row">
            <h1 className="cloud">
              Get your own <span>Cloud Phone System </span> up & running within
              minutes
            </h1>
            <p className="cloud-pd">
              Digitorzo helps in providing quality connectivity which
              indeed helps to get connected with the customers across the globe.
              We provide international numbers for U.S.A , UK, Australia,
              Canada, Africa , Germany and so forth. We ensure that HD call
              quality is maintained and the services are provided are free of
              cost. Our international toll free numbers can be put in use
              swiftly
            </p>
          </div>

          <div className="row mt-3 get">
            <div className="col-md-6 col-sm-6 col-lg-3 mt-2 mob_backgroung">
              <div className="arrow">
                <img src={Voip} alt="clint" />
              </div>

              <h1>VOIP Telephony</h1>
              <p>
                Even with low capacity now one can make and receive unlimited
                high definition calls with VOIP Telephony services of Digitorzo
                Networks LLC
              </p>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-3 mt-2 mob_backgroung">
              <div className="arrow">
                <img src={Virtual} alt="clint" />
              </div>
              <h1>Call Center Solutions</h1>
              <p>
                Hosted call center solution is primary telecommunication
                solution which aid the organization to get the communication in
                the best .
              </p>
            </div>

            <div className="col-md-6 col-sm-6 col-lg-3 mt-2 mob_backgroung">
              <div className="arrow">
                <img src={tollfreenumber} alt="clint" />
              </div>
              <h1>Toll Free Number</h1>
              <p>
                Toll Free Number service which comes with abundant features
                which helps them to serve the clients globally at their comfort
                We
              </p>
            </div>
            <div className="col-md-6 col-sm-6 col-lg-3 mt-2 mob_backgroung">
              <img src={callcenter} alt="clint" />
              <h1>Support</h1>
              <p>
                Support cat as a cushion for the business which ensures that
                even in uncertain circumstances the business will keep on going.
                We live
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <img src={Rectangle} className="width" />
      </div>

      <div className="section-padding">
        <div className="container">
          <div className="row">
            <h1 className="cloud_26">
              Comprehensive Communication Solutions For Your Business
            </h1>
            <p className="cloud-pd text-center">
              Digitorzo firmly believes in providing high quality
              telephony which comprises of Hosted PBX and cloud hosted Business
              Phone system that has all the traits of traditional VoIP and cloud
              once installed, you will be in a position to bring changes on the
              table
            </p>
          </div>
          <div className="bg-themeyello btn-3 mt-5">
            <div className="row mt-3 get">
              <div className="col-md-6 mt-2">
                <img src={Img1} alt="clint" className="image-marg" />
              </div>
              <div className="col-md-6 mt-2">
                <div className="py-5">
                  <h2 className="smibold">Absolute Communication Solution</h2>
                  <p>
                    Digitorzo Hosted call center solution is primary
                    telecommunication solution which aid the organization to get
                    the communication in the best possible way by reaching out
                    the right people at the right point in time and hence making
                    the best of the opportunity keeping the pace with the
                    changing time . <br />
                    <a href="/about-us">
                      <button className="mt-3 my-button">
                        Learn More {<ArrowRightAltIcon />}
                      </button>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center">
        <img src={Rectangle} className="width" />
      </div>

      <div className="section-padding">
        <div className="container">
          <div className="row">
            <h1 className="cloud_26">
              Advance Features of Digitorzo
            </h1>
            <p className="cloud-pd text-center">
              We at the end of the day reduces the possibility of getting the
              calls missed which is done with the help of trait as Call –Hunt,
              Call Queue Management, Call parking and so forth.
            </p>
          </div>
          <div className="bg-theme mt-5">
            <div className="row mt-3 get">
              <div className="col-md-6 mt-2">
                <div className="py-5">
                  <h2 className="smibold">
                    Benefits of Using Advanced ACD PBX
                  </h2>
                  <p className="text-justify">
                    Putting the callers on hold for long leaves negative impact.
                    However, ACD PBX consist of a digital operator that
                    understands the exact needs of the callers and divert their
                    call to the correct department and to the right person
                    within a short span of time. This resolves the caller's
                    concern quickly and makes it simple to turn the caller into
                    loyal customers.
                    <br />
                    <a href="/acd">
                      <button className="mt-3 my-button">
                        Learn More {<ArrowRightAltIcon />}
                      </button>
                    </a>
                  </p>
                </div>
              </div>
              <div className="col-md-6 mt-2">
                <img src={Img2} alt="clint" className="image-marg" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <img src={Rectangle} className="width" />
      </div>

      <div className="section-padding">
        <div className="container ">
          <div className="row">
            <h1 className="cloud">
              <span>Cloud Hosted Solutions </span> to Serve Enterprise-Grade
              Contact Center.
            </h1>
            <p className="cloud-pd text-center">
              Digitorzo Hosted call center solution is primary
              telecommunication solution which aid the organization to get the
              communication in the best possible way by reaching out the right
              people at the right point in time
            </p>
          </div>

          <div className="row mt-5">
            <div className="col-md-4 mt-5">
              <ul className="style-none">
                <li className="mt-2">
                  <b className="theme-color">Hosted PBX</b>
                  <p>
                    The Digitorzo hosted PBX service is one stop
                    solution for all communication requirements
                  </p>
                </li>
                <li className="mt-5">
                  <b className="theme-color">Call Center Solutions</b>
                  <p>
                    Digitorzo Hosted call center solution is primary
                    telecommunication solution which aid the
                  </p>
                </li>

                <li className="mt-5">
                  <b className="theme-color"> VOIP Servers </b>
                  <p>
                    ven with low capacity now one can make and receive unlimited
                    high definition calls with VOIP Telephony services
                  </p>
                </li>

                <li className="mt-5">
                  <b className="theme-color">Toll Free Number</b>
                  <p>
                    Digitorzo provides Toll Free Number service which
                    comes with abundant features which helps them
                  </p>
                </li>

                <li className="mt-5">
                  <b className="theme-color">SIP Trunking</b>
                  <p>
                    Digitorzo brings all the traits of SIP Trunking
                    along with well-built controls which are user friendly.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-md-8">
              <img src={img3} alt="section" className="width_Full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
