import React from "react";
import {
  Outbond_Call,
  Inbond_Call,
  Pict,
  img,
  img1,
  img2,
  img33,
  tfn5,
} from "../../assets";

const VoipTelephony = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Voip <span class="span-color">Telephony</span>
            </h1>
            <p class="hone-p">
              Digitorzo has come up with advanced features especially
              for home based businesses so that best quality can be delivered.
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <h1 className="home-text py-2">
            Empower your business setup with finest
            <span class="span-color"> phone connectivity </span>
          </h1>
          <p>
            Even with low capacity now one can make and receive unlimited high
            definition calls with VOIP Telephony services of Digitorzo Networks
            LLC, it comes with flexibility and 24 by7 support. The Digitorzo
            Networks LLC employ telephone setup within minutes and one can ask
            for the number of extensions. In order to make it cost effective
            VOIP Telephony services offered by Digitorzo support can
            work with any internet connected device.
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img src={img} className="img-fluid" alt="inbond call"></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Home phone –
              </div>
              <p className="f-18">
                Digitorzo has come up with advanced features
                especially for home based businesses so that best quality can be
                delivered. User friendly attributes such as routing, transfer, call
                forwarding will help one to make best possible use of the
                opportunity. Device Independence trait will help one to get the
                VOIP services connected on smartphone, Pc and tablet as well.
                One can also get the freelance employees connected to each other
                free to cost
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Digitorzo Hosted PBX
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Upper hand </span> with Digitorzo
                Networks LLC Home Phone Service
              </h1>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; One can use the same number and even get the new
                    phone system connected{" "}
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Easy management of calls even being away from
                    workplace
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Customized version in order to meet the business
                    needs.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;One can also use local and the virtual number.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;To overcome any concern at any point in time will get
                    service round the clock
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img src={tfn5} className="img-fluid" alt="inbond call"></img>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img src={img1} className="img-fluid" alt="inbond call"></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Business phone –
              </div>
              <p className="f-18">
                THE Digitorzo gives you the upper hand to make your
                business communication fruitful just by adding or deleting the
                extension, routing the call just to ensure that need of the hour
                is aptly taken care by just few clicks at any point in time and
                from any location. Traits like real time monitoring, calls
                conferencing, reporting and so forth and hence we will take care
                what you are looking for is provided
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Digitorzo Hosted PBX
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr"> Upper hand </span> with Digitorzo
                Networks LLC Business Phone service
              </h1>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Installation set up is free
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;One can make free calls among the added extensions
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Works flawless with mobile device.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Can also use other features as Internet.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Fax, Voicemail options.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Dialer and Business Software can be used together.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={Outbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={Inbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                IP Phone system –
              </div>
              <p className="f-18">
                has proven to be an aid to the business however to leave more
                impact time has come to switch to Digitorzo. We host
                the IP system on cloud so that all the features of the
                traditional as well as the new version can be used for the easy
                management and high productivity. Our professionals will ensure
                that one does not have to go with the hassle of upgrading
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Digitorzo Hosted PBX
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr"> Amazing traits </span>of Digitorzo
                Networks LLC IP Phone System
              </h1>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Entire IP Phone system can be put in use by the
                    experts without consuming much of your valuable time
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Organization located at different locations can be
                    connected free of cost
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; One can get the free trial and we also do not charge
                    anything for installation, set up and nothing for
                    customizing.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;One stop solution for all concern of the call center
                    at an economical price.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={Outbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img src={img} className="img-fluid" alt="inbond call"></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Virtual Office Phone system –
              </div>
              <p className="f-18">
                as we are growing with time so is the business, with the Digitorzo
                Networks LLC Virtual Office Phone System there is no need of the
                physical office, the system allows high quality voice calling
                and the employees for all the other locations domestic as well
                as international can get connected to each other free of cost
                which will help each one of them to analyze their work and they
                will be able to work in sync with the help of attributes such as
                Hosted IVR system, call routing , call recording, forwarding and
                so forth which will indeed work without local server setup.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Virtual Office Phone System
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Prominent features </span> of the
                Virtual Office Phone System
              </h1>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Can get the extensions added or removed
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; No location hindrance
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Workability with mobile devices.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Live monitoring access.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Can also get Toll free number
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img src={img2} className="img-fluid" alt="inbond call"></img>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img src={img33} className="img-fluid" alt="inbond call"></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Virtual Office Phone system –
              </div>
              <p className="f-18">
                VoIP Telephony at Digitorzo provides
              </p>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;In house call monitoring.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Feasibility with Business Software.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Works with the prevailing set up.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Can be used on any advice.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default VoipTelephony;
