import React from "react";
import { tfn, Inbond_Call, Pict } from "../../assets";

const ACD = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              ACD<span class="span-color"> PBX </span>
            </h1>
            <p class="hone-p">
            Improve your customers call experience with advanced features of ACD PBX.
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p className="f-18">
            In today's time it is a struggle to get in touch with the first
            point of contact of the organization because of huge number of calls
            coming in at the same point in time and hence it become struggle to
            answer the caller leaving the negative impact and may result in
            losing them to overcome the same we have Advanced Automatic Call
            Distribution from the Digitorzo which assists you in
            managing the clients and as a result retaining them.
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">

          <div className="text-center">
            <h1 className="fw-bold servicetex">
              {" "}
              Benefits of Using Advanced ACD PBX
            </h1>
            <p className="f-18">
            ACD PBX can be used as a wizard which helps in understanding the need of
              the caller, as we all know time is money no one wants to wait on
              the hold for minute concern which already discussed leaves a
              negative impact however on the other side if the concern is dealt
              with in short span of time one gets the feeling of being valued
              and gives an impact which is positive and thus makes it simple
              from making the caller into customer Advanced ACD has a digital
              operator hence as the call lands the call will be greeted
              automatically and they will come across a prerecorded menu while
              the representatives are occupied hence by selecting the options
              one will be able to get connected to correct department while
              going the following options
            </p>
            <br/>
          </div>
        </div>
        <br></br>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Digitorzo ACD PBX
              </div>

              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Go to the next{" "}
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Return to the previous menu
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Dial on extension.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Repeat .
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp;Return to the previous menu.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Call operator.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={tfn}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
          <div className="text-center">
          <p>  We have laid our emphasis on making it easy for the clients and also
            ensuring that the demands of the customer are being taken care by
            making their call land in the right department. We cannot compare
            the worked done by the Advanced ACD as in comparison to the human
            operator it is very prompt and efficient and indeed can handle
            numerous calls at one point in time, you can also avail the option
            of setting the priorities chosen by caller for future reference Once
            the call lands within the right department Advanced ACD work is not
            over yet, it will get the call connected to the extension which is
            free and at the same time the intimation is given to the
            representative that calls are in queue and they will be required to
            wrap up the calls faster When the call lands with the
            representative, they have the sufficient information which will give
            the edge to the representative as they will have the necessary
            information and will be helpful in understanding the customer and
            can provide prompt solution as a result of which they will be
            satisfied </p>
            <br/>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ACD;
