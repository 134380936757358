const navbarItems = [
    {
        title: 'Product',
        link: '/about',
    },
    {
        title: 'Solutions',
        link: '/solutions',
    },
    {
        title: 'Resource',
        link: '/resource',
    },
    {
        title: 'Resource',
        link: '/resource',
    },
    {
        title: 'Company',
        link: '/Company',
    },
]

export default navbarItems