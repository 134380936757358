import React, { useState } from "react";
import Sidebar from "react-sidebar";
import {
	DrawerNavigationHeader,
	DrawerNavigation,
} from 'react-bootstrap-drawer';
import { Nav } from "react-bootstrap";

const NavSidebar = () => {
  const [sidebarOpen, setsidebarOpen] = useState(false);

  const onSetSidebarOpen = (open) => {
    setsidebarOpen(open);
  };

  return (
    <Sidebar
      sidebar={ <DrawerNavigation>
				{ /* Standard react-bootstrap Nav.Item / Nav.Link */ }
				{ /* Caveat: CSS provides custom styles */ }
				<Nav.Item>
					<Nav.Link href="/">Home</Nav.Link>
				</Nav.Item>

				<Nav.Item>
					<Nav.Link href="/settings">Settings</Nav.Link>
				</Nav.Item>
			</DrawerNavigation>}
      open={sidebarOpen}
      onSetOpen={onSetSidebarOpen}
    >
      <button onClick={()=> setsidebarOpen(prev => !prev)}>Main content</button>
    </Sidebar>
  );
};

export default NavSidebar;
