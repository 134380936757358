import React from 'react'
import "./Index.css"
import Call_Centen from "../../assets/servicesImage/call-center-banner.png"
import Inbond_Call from "../../assets/servicesImage/inbond-call.png"
import Outbond_Call from "../../assets/servicesImage/outbond-call.png"
import Blend_call from "../../assets/servicesImage/blended-call.svg"
import Benefit from "../../assets/servicesImage/benefits.png"

const Services = () => {
    return (
        <div>
           
            <section className="CantColor">
                <div className="container-sm text-senter pt-5 ">

                    <h1 className="pt-5">Cloud Hosted Solution
                    </h1>


                    <h1 className=" " > to Serve Enterprise-Grade Contact Center</h1>

                    <p className=""> Digitorzo Hosted Call center Solution is primary telecommunication Solution witch aid the organization to get the communication in the best possible way by reaching out the right people at the night point in
                        time and hence making the best of the opportunity the pace with
                        changing time.our user friendly software includes real time visual monitoring and advanced dailing feature wittch will indeed help me
                        in impruving contact ratio and also help in adhering to compliance Absolute Solution for call center Needs </p>
                </div> </section>
            <section className="mt-5 pt-3 sec3color">
                <div className="container text-center mt-5">

                    <div className="row ">
                        <div className="col-lg-6  ">
                            <img src={Inbond_Call} className="img-fluid" alt="inbond call"></img>
                        </div>
                        <div className="col-lg-6 mt-5 ">
                            <div class=" text-white text-uppercase btn-warning text-start pt-1 dip">
                                Lorem lpsum simply dummy text
                            </div>
                            <h1 className="fw-bold hdding text-start mt-1">Inbond Call Center</h1>
                            <div className="text-start mt-1">A brilliant mechanism which help the center to manage heavy call flow or in other words call traffice its features are listed below</div>
                            <div className="row">
                                <div className=" col-lg-6  ">
                                    <ul className="mt-3 ">
                                        <li className="text-start pt-3">Call Waiting</li>
                                        <li className="text-start pt-3">Malti Level IVL</li>
                                        <li className="text-start pt-3">Advance Call Distribution</li>
                                        <li className="text-start pt-3">Music on Hold</li>
                                    </ul>

                                </div>
                                <div className="col-lg-6 ">
                                    <ul className="mt-3">
                                        <li className="text-start pt-3">Call Waiting</li>
                                        <li className="text-start pt-3">Malti Level IVL</li>
                                        <li className="text-start pt-3">Advance Call Distribution</li>
                                        <li className="text-start pt-3">Music on Hold</li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                    </div>
                </div></section>
            <section>
                <div className="container-sm text-center mt-5" >
                    <div className="row">
                        <div className="col-lg-6 mt-5 pt-3 ">
                            <div class=" text-white text-uppercase btn-warning text-start dip">Lorem lpsum simply dummy text</div>
                            <h1 className="fw-bold hdding text-start mt-1 "> Outbond Call Center</h1>
                            <div div className="text-start mt-1">Allows one to make call which indeed increases
                                the productvity and reduces the agents idle time,one can make the best use of this by using the listed features</div>
                            <div className="row">
                                <div className=" col-lg-6  ">
                                    <ul className="mt-3">
                                        <li className="text-start pt-3">Call Waiting</li>
                                        <li className="text-start pt-3">Malti Level IVL</li>
                                        <li className="text-start pt-3">Advance Call Distribution</li>
                                        <li className="text-start pt-3">Music on Hold</li>
                                    </ul>

                                </div>
                                <div className="col-lg-6 ">
                                    <ul className="mt-3">
                                        <li className="text-start pt-3">Call Waiting</li>
                                        <li className="text-start pt-3">Malti Level IVL</li>
                                        <li className="text-start pt-3">Advance Call Distribution</li>
                                        <li className="text-start pt-3">Music on Hold</li>
                                    </ul>
                                </div>
                            </div>


                        </div>
                        <div className="col-lg-6 ">
                            <img src={Outbond_Call} className="img-fluid" alt="out_call"></img>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container-sm text-center mt-5">
                    <h1 className="fw-bold hdding "> <span className="text-success"> Blended</span> Call Center </h1>
                    <div className="mt-2"><h5 className="p-150">
                        Lorem lpsum is simply dummy text of the printing and typsetting industry.Lorem lpsum has been the industry's standard dummy text ever since the 1500s whene an unknown print
                    </h5></div>
                    <div >
                        <div >
                            <img src={Blend_call} className="img-fluid" alt="Blend_call"></img>
                        </div></div>
                </div>
            </section>
            <section>
                <div className="container-sm text-center ">
                    <div className="row">

                        <div className="col-lg-6 mt-5 pt-4  ">
                            <h1 className="fw-bold hdding text-start">The <span className="text-success" >Digitorzo </span></h1>
                            <h1 className="fw-bold hdding text-start">Call Center Solution  Benefits</h1>
                            <div>
                                <ul className="">
                                    <li className="text-start pt-2">Delivers a well-balanced platform which will be surely cost effective.</li>
                                    <li className="text-start pt-2">Ensure smooth movement between inbound and outbond calls.</li>
                                    <li className="text-start pt-2">Ensures no delay quality is also maintanted.</li>
                                    <li className="text-start pt-2">Offers real time monitoring which will indeed help in compliance abiding.</li>
                                    <li className="text-start pt-2">Ensures that data is analyzed properly.</li>
                                    <li className="text-start pt-2">Help in increasing the reachability.</li>
                                    <li className="text-start pt-2">Abreast of latest technology in order to meet day to day demands.</li>
                                </ul></div>

                        </div>
                        <div className="col-lg-6 ">
                            <img src={Benefit} className="img-fluid" alt="Benefit"></img>
                        </div>
                    </div>
                </div>
            </section>
            <section className="mb-4">
                <div className="container-sm text-center mt-5">
                    <h1 className="fw-bold hdding">Getting started takes less than 5 minutess </h1>
                    <div >
                        <h5 className="p-150" >Get a new phone number or port your existing numbers, invite your team members and start making & recieving calls </h5>
                    </div>
                    <div>
                        <button type="button" className="btn btn-warning bt-margin">Get Start Now</button>
                    </div>
                    <div>
                        <p className="">Lorem lpsum is simply dummy text of the printing and typsetting industry.Lorem lpsum has been the industry's </p>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services

