import React from "react";
import { tfn5, tfn3 } from "../../assets";

const dailbyname = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Dail By<span class="span-color"> Name </span>
            </h1>
            <p class="hone-p">
              Dial by Name /Extension attribute by the Digitorzo has
              been arranged with the Business Phone service in order to Speedup the dailing process

            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p className="f-18">
            Virtual PBX number system make it very easy for the employees to get
            connected to each other. Business Phone Services come with two
            feature Dial by Name and Dial by Extension. Phone service by Digitorzo
            Networks LLC will ensure that the calling among the employees will
            be effortless and, one can also opt for the customization options
            and can any employee without taking much of the time.
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img src={tfn3} className="img-fluid" alt="inbond call"></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                It operates in the following way
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Dial by</span> Name –
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  it dials the call by recognize the name of the you are trying
                  to reach out. One can use the keyboard or simply call out the
                  name of the employee one wants to get the call connected to,
                  in order to put the trait in use one has to either call out
                  the first name or for the same the first three letters of the
                  first name of the contacts and one important thing to consider
                  is that the employee one is trying to dial should be saved in
                  contacts
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Dial-By-Name
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Dial by </span> Extension –
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  One can reach the employee just by dialing the extension and
                  one will not be required to dial the complete number, the way
                  to add the extension for the employees and for the department
                  is done through admin of the network
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img src={tfn5} className="img-fluid" alt="inbond call"></img>
            </div>
          </div>
          <div className="text-center mt-3">
            <h1 className="home-text">
              {" "}
              How Dial by Name/ Extension will impact Business Phone{" "}
            </h1>
            <p>
              Dial by Name /Extension attribute by the Digitorzo has
              been arranged with the Business Phone service in order to quicken
              the process as one will not be required to manually look for the
              extension or the name in the saved contacts however the automated
              search will complete the call after confirming the apt destination
              and one will not have to wait for the personal meeting in order to
              carry out the work without any delay and it also helps in saving
              the time as for small conversation one will not be required to
              move from location to another
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default dailbyname;
