import React from "react";
import { HomeBanner, Hosted, Pict } from "../../assets";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import { FaBeer } from "react-icons/fa";

const Policy = () => {
  return (
    <div>
      <div className="containe  bg-themebg">
        <div className="container">
          <div className="section-padding text-center">
            <h1 class="home-text py-2">
              Privacy <span class="span-color"> Policy </span>
            </h1>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="section-padding">
          <p className="f-18">
            Digitorzo firmly believes in providing high quality
            telephony which comprises of Privacy Policy and cloud hosted
            Business Phone system that has all the traits of traditional VoIP
            and cloud once installed, you will be in a position to bring changes
            on the table
          </p>
          <br />
          <p className="f-18">
            The updated the name of the company Privacy Notice comes into effect
            from Date
          </p>
          <br />
          <p className="f-18">
            Name of the company and its worldwide affiliaties and subsidiaries
            (collectively “name of the company’’, “us’’, “we”, “our”) are
            devoted towards guarding your privacy while providing you with a
            positive experience on our website and also in using our products
            and services
          </p>
          <br />
          <p className="f-18">
            The Privacy Notice (“Notice’’) will help one in getting the detail
            information on how and why we collect, use and share your personal
            data from our customers, website visitors and even the end users of
            our products and services (collectively “you”, “your’’) by means of
            communications with you and also with our products and services.
            When you view our website, our sub domains, our desktop, IP desk
            phone and mobile applications or in fact use our unified cloud
            communications as well the services. The policy also considers your
            choices in regards to use, access, deletion as well correction of
            your personal information
          </p>
          <br />
          <p className="f-18">
            Name of the company’s Privacy Notice applies to name of the company
            websites as well to services that has some link to or reference this
            Notice. Add on information in relation to personal information
            practices may be provided in supplemental term and conditions,
            supplemental privacy statements or notices provided prior to or at
            the time of data collection
          </p>
          <br />
          {/* <h1 className="home-text py-2">
            <span class="span-color"> Who We Are </span>
          </h1> */}
          <b className="f-18 py-3 mt-3">
            We would suggest you to read this Notice in full in order to be sure
            that you are fully informed however if you are interested just to go
            access a particular section of this Notice, then you simply click on
            the same
          </b>
          <br />
          <div className="mt-1">
            <ul className="p-0">
              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Information we collect
              </li>
              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Information we use
              </li>
              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Sharing the information to third parties
              </li>
              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; privacy rights
              </li>

              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Third – party sites
              </li>

              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Blogs and Forums
              </li>

              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Security
              </li>

              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Data retention
              </li>

              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; International data transfers
              </li>

              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Children’s privacys
              </li>

              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Updates to this Notice
              </li>

              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Contact us
              </li>
            </ul>
          </div>

          <h2 className="home-text py-2">
            <span class="span-color f-21"> Information We Collect </span>
          </h2>

          <p className="f-18">
            As used in this Notice, “personal information’’ or “personal data”
            which indeed means any information or data relating to any
            recognized or recognizable natural person or household (an
            “identifier’’) which we might process in connection to our Websites,
            Apps and Services, recognized or recognizable natural person or
            household is one who can be identified directly or indirectly , in
            specific by reference to an identifier such as name, an
            identification number , location data , an online identifier or to
            one or more factors in relation to the physical, physiological ,
            genetic, mental , economic , cultural or social identify of that
            natural person or household
          </p>

          <h2 className="home-text py-2">
            <span class="span-color f-21">
              Information which individual actively provide to us –
            </span>
          </h2>

          <p className="f-18">
            We may ask one to provide certain personal information when one
            create an Digitorzo account, when you visit and view
            certain parts of our Website, Apps and Services. The personal
            information you are asked to provide will depend on the factors that
            you are asking for the information from us. We collect information
            which you provide to use by visiting our Website, Apps or Services
            which may include email address, postal address, phone numbers,
            employee name, user name, information about the type of service
            required, marketing preferences, job titles, credit card
            information, transactional information it will also include any
            communication, inquiries contact or other information which you have
            provided while you are using the services. The information provided
            by you will only be used in a manner that helps one to achieve
            specific purposes for which it is been collected.
          </p>

          <h2 className="home-text py-2">
            <span class="span-color f-21">
              Information we collect from end users on behalf of Name of the
              company customers –
            </span>
          </h2>

          <p className="f-18">
            In case if you are not a Name of the company customer but you are
            still engrossing in communication powered by Name of the company
            Services, we may collect and store the personal information on
            behalf of a Name of the company customer. You may directly provide
            this information to us in a way (by filling out a form or at the
            time of signing of an account to publish and share content on Name
            of the company’s communities) or indirectly in a way (by providing
            the personal information to the Name of the company customer while
            ongoing conversation hence the same information enters our system )
          </p>
          <br />
          <p className="f-18">
            Information may include details as full name, email address,
            username, other information we collect as necessary to offer the
            relevant Website, App or Service. We use the same information in
            connection with providing our Website, App or Service to the Name of
            the company client on whose behalf we store and collect the
            information which is otherwise necessary to administer and maintain
            our Website, Apps or Services and even for the other genuine
            business purposes given permission by our government with the
            relevant Name of the company client.
          </p>
          <br />
          <p className="f-18">
            Some Name of the company products and services may also use and
            store personal information about one from customer’s social media
            accounts or the account which they have with the third party
            services, when the customer selects to connect its account Name of
            the company accounts with social media, the information which is
            publically available will be stored. We use this information in
            providing our services to our clients as it will help our customers
            to respond to social media posts and messages within their Name of
            the company accounts and they will also be able to track and analyze
            their interaction with End Users via social media
          </p>
          <br />
          <p className="f-18">
            Information we collect automatically when one visits our Website,
            Apps or use our services
          </p>
          <br />
          <p className="f-18">
            When an individual visits our Website or use the Apps or services,
            we might collect some information automatically from your device. In
            some jurisdiction which includes European Economic Area the
            information can be taken as personal information under applied data
            protection laws
          </p>

          <br />

          <h2 className="home-text py-2">
            <span class="span-color f-21">
              Using of the information – we always maintain the track of the
              activity in regards to the Website, Apps or Services, we may end
              up collecting
            </span>
          </h2>

          <p className="f-18">
            {" "}
            Traffic data it refers to the communication that happens through our
            platform by the means of calls, team chat, video conferencing, SMS
            which indeed help us to convey effectively and efficiently.
          </p>
          <br />
          <p className="f-18">
            Network Monitoring data indeed helps us to sustain the security and
            swiftness of our internal networks
          </p>
          <br />
          <p className="f-18">
            Log data when the Services or Apps are being used and the Website is
            being accessed including IP address, Internet Service provider,
            browser type, exit pages, files which were viewed on the website,
            operating system, date, time, clickstream data in order to analyze
            the trends in total as well administer the site
          </p>
          <br />

          <p className="f-18">
            Device data including the mobile phone number and also other related
            information regards to mobile devices such operating system and if
            one is using our services with the help of App then model and for
            other devices the information which is collected by cookies. We use
            different various other technologies as well to collect the
            information not only this we also save cookies to computer and
            collect information.
          </p>
          <br />

          <p className="f-18">
            Call Detail Records helps to get data through a telephone call or
            other telecommunication transactions, the record consists of various
            traits of the call as time, duration, source number, destination
            number and the completion status of the call.
          </p>
          <br />
          <p className="f-18">
            {" "}
            Meta data which is created from other data including size,
            formatting, other characteristics of a data item
          </p>
          <br />
          <p className="f-18">Email and communications with us</p>
          <br />
          <p className="f-18">Billing data which includes any payment data</p>
          <br />

          <p className="f-18">
            Data which is available publically from End User Accounts As already
            informed we collect data or information by using various
            technologies which may even include cookies when you access our
            Website or use our App or Services.
          </p>
          <br />

          <h2 className="home-text py-2">
            <span class="span-color f-21">
              Information we collect from third parties –
            </span>
          </h2>
          <p className="f-18">
            We may collect information as Name, email address, postal addresses
            and city of residence of individuals available with third party in
            order to market our products and services to these individuals and
            we ensure that it is carried out in compliance with the law
            applicable. We get this information from third parties after
            checking that they either have your consent or otherwise they are
            legally permitted / required to disclose individual’s personal
            information to us.
          </p>
          <br />
          <p className="f-18">
            We may get the personal information about the individual from other
            available sources which also includes database publically available
            or even the third parties from where we have purchased data and we
            might combine his data with the one which we already have about you
            which will indeed be in accordance with applicable laws. This will
            help us to update, expand and analyze our records, helps us in
            recognizing new customers and make you available products and
            services that caters to your interest
          </p>
          <br />
          <p className="f-18">
            We might even collect personal information about you from other
            applications as well depending upon if you have chosen to amalgamate
            Name of the company Apps or services with other Apps or services
          </p>
          <br />

          <h2 className="home-text py-2">
            <span class="span-color f-21">Information we use -</span>
          </h2>

          <p className="f-18">
            We may use the collected information about you for different
            purposes which even includes-
          </p>
          <br />
          <p className="f-18">
            Manage, operate, protect and maintain the Website, Apps or Services
          </p>
          <br />
          <p className="f-18">
            Complete transaction and send linked information which even include
            transaction conformation and invoices
          </p>
          <br />
          <p className="f-18">
            Cope and upgrade your use of Website, Apps or Services
          </p>
          <p className="f-18">
            Compose and furnish testimonials regarding the Website, Apps or
            Services.
          </p>
          <br />
          <p className="f-18">
            To ensure the availability of our Website, Apps or Services to
            customers for service and support
          </p>
          <br />
          <p className="f-18">
            Audit and stop fraudulent activities, unsanctioned access to
            Website, App or Services as well other illegal activities
          </p>
          <br />
          <p className="f-18">
            For any purpose about which we send notify and receive your consent
            from your end
          </p>
          <br />
          <p className="f-18">
            To help customize your experience and refocus you for advertising
            purposes
          </p>
          <br />
          <p className="f-18">
            Answer to inquiries and requests and make you available the
            information and access to resources that one has requested to
          </p>
          <br />
          <p className="f-18">
            Accumulation and examine your and use of the Website, App or
            Services for trend monitoring, marketing and advertising purpose
          </p>
          <br />
          <p className="f-18">
            Send you technical alerts, updates, security notifications as well
            administrative communications
          </p>
          <br />
          <p className="f-18">
            We and our third party marketing service providers end up using the
            information Customers send to us for our marketing purposes, if this
            is in accordance with the marketing preferences and the applicable
            law one also has the option to opt of our marketing, for more
            information see the Unsubscribe from mailing list section below.
          </p>
          <br />

          <h2 className="home-text py-2">
            <span class="span-color f-21">
              Legal Basis for processing personal information (European Economic
              Area)
            </span>
          </h2>

          <p className="f-18">
            As we collect personal information from you in connection with using
            our Website, App or Service within European Economic Area our legal
            basis for collecting and using the personal information it will
            depend on the personal information in the way we collect it. We
            collect personal information only when we your consent to do so, we
            need your personal information where we have to reach out to you or
            if it is used in the legitimate interest and not revoke by your data
            protection interest or fundamental rights and freedom however in
            some situations we may be required to collect the personal
            information in question. We may ask you to provide your personal
            information in order to meet the legal requirement or even to enter
            in a contract with you and the same will be made clear at the
            relevant time and will also let you know if providing with personal
            information is necessary or not and in case it is mandatory and the
            same is not provided what can be the consequences. In the same way
            when we collect and use personal information in dependence on our or
            third party’s genuine interest and even those interest which are not
            listed above or one may check Information We Use section and you be
            given the clear information what the legitimate interest at the
            relevant time. If you still have concern or seeking more information
            about the legal basis on what we collect and use personal
            information one can reach us through our contact details available
            under Contact Us
          </p>

          <h2 className="home-text py-2">
            <span class="span-color f-21">
              Sharing and Disclosure of Information to Third Parties
            </span>
          </h2>

          <p className="f-18">
            We will not rent or sell any of your personal information however we
            might share and reveal your information which we have collected with
            the third parties primarily for legitimate business or legal purpose
            in reference with applicable law
            <br />

          </p>

          <p className="f-18">
          • Name of the company and even its worldwide affiliates consistent with this Notice for data processing
          </p><p className="f-18">
          •  Business partners, contractors, vendors and authorized third party agents, to
          </p><p className="f-18">
          • Operate, deliver, improve and customize our services
          </p><p className="f-18">
          • Furnish support and technical services.
          </p><p className="f-18">
          •  Transmit marketing as well other operational communication which are related to services
          </p><p className="f-18">
          • Law enforcement agencies, regulatory or government bodies and even the third parties in order to respond to legal process, abide by legal obligation, protect or defend our rights, interests or property or that of third parties or check wrongdoing in relation with the Website, App or Services
          </p><p className="f-18">
          • Third parties in connection with potential or actual. Sale, merger, acquisition, financing or reorganization of our business
          </p><p className="f-18">
          • List of current group companies is available here Have to write down the link
          </p>


          <h2 className="home-text py-2">
            <span class="span-color f-21">Your Privacy Rights</span>
          </h2>

          <p className="f-18">
            Update and access to your information
            <br />
            Where we use personal information collected through our Website,
            Apps or through our Services for our account management, billing or
            marketing purposes and where required by applicable law and also we
            allow individual with the option to access, review, modify and
            delete any such personal information that we exercise
          </p>

          <h2 className="home-text py-2">
            <span class="span-color f-21">Request from End Users</span>
          </h2>
          <p className="f-18">
            If you suspect Name of the company might have collected or saved
            personal information from you on behalf of a Name of the company
            customer, you are requested to contact the Name of the company
            customer with any request in relation to your personal information
          </p>
          <br />
          <h2 className="home-text py-2">
            <span class="span-color f-21">
              Your Privacy Rights as a data subject protected by EEA law or
              California Resident
            </span>
          </h2>
          <p className="f-18">
            If you are an EEA person, if you are located in the EEA or if you
            are a California resident you will have wider rights in regards to
            access and delete your personal information, to object to or limit
            processing of personal information, to correct your personal
            information or request flexibility of your personal information In
            case if we are the end user whose personal information is collected
            on behalf of Name of the company, you are requested to contact the
            Name of the company customer directly to make such request however
            in other circumstances one can summit the request through online
            portal and all we be taken care in accordance with applicable laws
            If we have collected and used your personal information with your
            consent and it is also protected by EEA law, one can withdraw
            consent at any time having said that it will not have any lawful
            impact on the processing conducted prior to your withdrawal nor it
            will have impact on processing of your personal information
            conducted in reliance on lawful processing grounds other than
            consent One also has the right to complain to the local data
            protection authority at any point in time, when using personal
            information to provide our services, we do it on behalf of our EEA
            Customers and in accordance with their instructions which indeed
            means if one wants to access, review, modify or delete any personal
            information we process on behalf of Name of the company customer,
            applicable under EEA law one should contact that customer with
            request, we will then help that customer with the specific request
            in regards to the instructions
          </p>
          <br />

          <h2 className="home-text py-2">
            <span class="span-color f-21">
              Additional Privacy Rights as a California Resident
            </span>
          </h2>

          <p className="f-18">
            If one is the resident of California and is receiving Name of the
            company Services or visit our Website or App as a private individual
            what is meant not as a member or representative of a company or as a
            part of the organization, you may have the right to receive a free,
            yearly accounting of Information recognized by third party company
            to whom we may have provided, within the past year personal
            information pertaining to you for our direct marketing purposes A
            detailed description of the categories of personal information
            disclosed In case you are a resident of California who qualifies to
            receive such an accounting and would like to go with one please
            email on have to write the email address. We don’t engross in the
            sale of personal information as per the California Law
          </p>
          <br />
          <h2 className="home-text py-2">
            <span class="span-color f-21">Third - Party Sites</span>
          </h2>

          <p className="f-18">
            We give you the upper hand in receiving a variety of information
            related to our Website, App or Services and one can manage the
            communication preferences through the following methods By following
            the instructions which includes each promotional email from us to
            unsubscribe from that Sending us an email at unsubscribe and
            complete email address Visiting mention, the complete link Although
            these choices do not apply to service notifications or other
            required communication that are considered part of certain App and
            Services, which one may receive periodically unless one stop using
            or cancel the App or Services however in accordance with its terms
            and conditions
          </p>
          <br />

          <h2 className="home-text py-2">
            <span class="span-color f-21">Third - Party Sites</span>
          </h2>
          <p className="f-18">
            The Notice does not apply to, nor we are responsible for, the
            privacy, information or other practices of any third parties which
            include any third party operating any site or service to which the
            Website links including but it is not limited to social media sites.
            The insertion of a link on the website does not imply our
            endorsement of the linked site and it is advisable to check the
            privacy notices of those sites before providing your personal
            information to them
          </p>

          <h2 className="home-text py-2">
            <span class="span-color f-21">Blogs and Forums</span>
          </h2>
          <p className="f-18">
            On our Website one can publically access blogs or community forums.
            One should be completely aware that any information you provide in
            these areas is public and can be read, collected and can be used by
            others who access them and will be there on the public domain
            forever however if one wats to remove the personal information for
            our blog or the community forum for the same one can summit the
            request though our online portal , at times there can be a situation
            that we might not be able to remove your personal information and
            then in that case we will let you know and will describe the reason
            for the same hence it is always advisable that one is cautious while
            providing personal information in blogs and community forums
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21">Security</span>
          </h2>
          <p className="f-18">
            Keeping your information secure it is not only important to us but
            also prime job and for the same we maintain a proper technical and
            organizational safeguards in order to protect your personal
            information even at the time of transmission and once it is
            received. Name of the company has no control over or responsibility
            for the security or privacy policies or other sites on the internet
            you might visit, interact with or you might visit to buy products as
            well services however you even visit the website using the link from
            our website It is worth to note no Website, App or Services is
            completely secure however we aspire to protect our customer’s
            information using the measures as mentioned above and at the same
            time we cannot guarantee that unauthorized access,hacking, data loss
            or data breach will not occur
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21">Data Retention</span>
          </h2>

          <p className="f-18">
            We will only keep your personal information till it is required to
            fulfill purpose for which it was initially collected unless no
            retention period is required or permitted by law, for legal, tax or
            regulatory reason or other genuine and lawful business purposes. In
            case if we do not have any ongoing business where we need to use
            your personal information we will then delete the same or
            undesignated it.
          </p>

          <h2 className="home-text py-2">
            <span class="span-color f-21">International Data Transfers</span>
          </h2>

          <p className="f-18">
            If need be your personal information may be transferred and managed
            in countries other than you are a resident. Specially information
            which is collected outside the United States which even include
            European Economic Area may be transferred to or saved on our servers
            in United States and also in other countries as well where our group
            companies and third party service providers as well the partners
            operate and the point to consider is that they might have different
            data protection laws to the countries in which you reside Name of
            the company abide by the Privacy Shield Principles for all transfers
            of personal data from the EU, the UK, and Switzerland including the
            onward transfer liability provision. We ensure that we have taken
            suitable safeguards to ensure that your personal information will
            remain safe in accordance with this Notice and applicable laws. In
            respect of personal information which originates from the EU and
            Switzerland, Name of the company has self-certified to the EU-U.S
            Privacy Shield Frameworks and in order to view the same please click
            here If you still have any concern in regards to unresolved privacy
            or data use concern which we have not addressed aptly, please feel
            free to contact our U.S based third party dispute resolution
            provider at have to provide the complete link of the page In regards
            to the personal data received or transferred agreeable to these
            Privacy Shield Frameworks, Name of the company is subject to the
            regulatory enforcement powers of the US Federal Trade Commission.
            Under Certain conditions complete description on the Privacy Shield
            website, you may resort to binding arbitration when other dispute
            resolution procedures have been exhausted.
          </p>
          <br />

          <h2 className="home-text py-2">
            <span class="span-color f-21">Children’s Privacy</span>
          </h2>

          <p className="f-18">
            Name of the company does not cater to products or services which can
            be used by children under the age of 16 and we knowingly do not
            collect personal information from or about children under the age of
            16 and in any given situation if you believe that the child under
            the age of 16 has given any information please contact have to
            mention the complete email address
          </p>
          <br />

          <h2 className="home-text py-2">
            <span class="span-color f-21">Updates to this Notice</span>
          </h2>
          <p className="f-18">
            We may update this Notice from time to time in regards to changing
            legal, technical or business developments. In case if we change our
            Notice the same will be posted on his page in addition to updating
            the Last Updated date on the page at the top and for the same you
            will be notified directly as by posting a message or notification on
            the website or emailing the same before the changes come into effect
            and we would request to read the same on the regular basis in order
            to keep oneself completely abreast with the latest notifications.
          </p>
          <br />

          <h2 className="home-text py-2">
            <span class="span-color f-21"> Contact Us</span>
          </h2>

          <p className="f-18">
            In case of any questions, comments or concerns about this Notice,
            for the same please email our data protection officer at have to
            write the complete email address or you can also write to us at :
            support@Digitorzo.com
          </p>
        </div>
      </div>
    </div>
  );
};
export default Policy;
