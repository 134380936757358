import React from "react";
import { HomeBanner, Hosted, Pict } from "../../assets";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import { FaBeer } from "react-icons/fa";

const Aboutus = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg">
        <div className="container pt-5">
          <div className="section-padding">
            <h1 class="home-text py-2">
              About <span class="span-color"> Us </span>
            </h1>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="section-padding text-center">
          <h1 className="home-text py-2">
            Absolute
            <span class="span-color"> Communication Solution </span>
          </h1>
          <p className="f-18">
            Digitorzo firmly believes in providing high quality
            telephony which comprises of Hosted PBX and cloud hosted Business
            Phone system that has all the traits of traditional VoIP and cloud
            once installed, you will be in a position to bring changes on the
            table
          </p>
          <br />
          <h1 className="home-text py-2">
            <span class="span-color"> Who We Are </span>
          </h1>
          <p className="py-2 f-18">
            Digitorzo is well known providers of quality services
            which is cloud based and we provide services to all business type
            not only this we customize as per the need In order to provide best
            services we ensure that training is provided to all the engineers
            from time to time to keep them abreast with the latest technology We
            emphasize
          </p>
        </div>

        <div className="mt-1">
          <ul className="p-0">
            <li>
              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; There is no limit of the calls on inbound, outbound or even
              on blended calls as we know that it is directly proportionate to
              the quality
            </li>
            <li>
              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; Connection will become easier even with the branches
              located in remote areas which will indeed help the business to
              save time, as we firmly belief in time is money
            </li>
            <li>
              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; Free setup and trial – In order to ensure that productivity
              and overall growth of the organization improves we provide free
              setup and trial services.
            </li>
            <li >

              <img src={Pict} alt="check" className="width-20 pl-2"></img>
              &nbsp; There is no need for phone line we would only require a
              phone system hosted on cloud and your business will be able to
              save more than one can think of which was earlier spend on
              traditional PBX phone bills
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Aboutus;
