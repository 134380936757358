import React from "react";
import { Outbond_Call, Inbond_Call, Pict ,tfn,tfn1,tfn2,tfn3,tfn4,tfn5 } from "../../assets";
import { FaCheckCircle } from "react-icons/fa";

const Tollfreenumber = () => {
  return (
    <div>
      <section className="CantColor">
        <div className="container-sm text-center pt-5 ">
          <div className="section-padding   pt-5 hone-p  cloud-pd">
            <h1 className="pt-5 home-text">
              Toll Free
              <span className="text-success  home-text mt-5  "> Number </span>
            </h1>
            <p className="hone-p  ">
              Digitorzo provides Toll Free Number service which comes
              with abundant features which helps them to serve the clients
              globally at their comfort.
            </p>
          </div>
        </div>
      </section>
      <section className="section-padding ">
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 mt-5  ">
              <div className="host-col">
                <p className="f-18 ml-5 ">
                  Digitorzo provides Toll Free Number service which
                  comes with abundant features which helps them to serve the
                  clients globally at their comfort. We provide full aid to
                  clients by offering couple of utilities and tools with the
                   toll free numbers. With the help of these services it has
                  become very easy for our valued customers to reach us. Clients
                  can also give new updates and hence increasing the
                  productivity at the same time without having to get the
                  information provided by a representative at an individual
                  level. It also helps us to build the brand as the Toll Free
                  Number is unique and can be remembered easily Toll Free
                  Numbers generally start with prefixes as 800, 888, 877,833 or
                  833 it has advantages for both the user and the organization
                  At the user level toll free number is the first point of
                  contact for a concern At the organization level it not only
                  helps in building the brand but also helps increasing the
                  overall efficiency and calls can be used for the training
                  purposes as well for the future reference.
                </p>
              </div>
            </div>
            <div className="col-lg-6  ">
              <img
                src={tfn}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section>

      <section className="section-padding sec3color">
        <div className="container text-center ">
          <div className="row ">
            <div className="col-lg-6 mb-5 ">
              <img
                src={Inbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning text-center p-1 mb-4 dip">
                Digitorzo provides Toll Free Number
              </div>
              <h1 className="fw-bold  home-text text-start mb-3">
                Prominent Features of Digitorzo Toll Free Number are
                listed as below
              </h1>
              {/* <div className="text-start mt-1 hone-p">
                            A brilliant mechanism which help the center to manage heavy call
                            flow or in other words call traffice its features are listed
                            below
                        </div> */}
              <div className="row">
                <div className=" col-lg-6  ">
                  <ul className="mt-3 ul_li ">
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; Hosted IVR
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp;Call Forwarding
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>
                      &nbsp; Call Blast
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp;Music on Hold
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 ">
                  <ul className="mt-3 ul_li">
                    <li className="text-start pt-3">
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp;Call Recording
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp;Virtual Receptionist
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>
                      &nbsp; Call Routing
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp;Call Transfer
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding ">
        <div className="container text-center ">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning text-center p-1 mb-4 dip">
                Toll Free Numbers
              </div>
              <h1 className="fw-bold  home-text text-start mb-3">
                Additional Benefits that comes along with Business Toll Free
                Number
              </h1>
              {/* <div className="text-start mt-1 hone-p">
                            A brilliant mechanism which help the center to manage heavy call
                            flow or in other words call traffice its features are listed
                            below
                        </div> */}
              <div className="row">
                <div className=" col-lg-6  ">
                  <ul className="mt-3 ul_li ">
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; Low operational cost.
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; Better customer service.
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>
                      &nbsp; Geographical Flexibility.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 ">
                  <ul className="mt-3 ul_li">
                    <li className="text-start pt-3">
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; Increased productivity.
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; Secure Reliable & Feature rich.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-5 ">
              <img
                src={tfn1}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding sec3color">
        <div className="container-sm text-center ">
          <div className="  cloud-pdh">
            <h1 className=" home-text">
              Different type of numbers that
              <span className="text-success  home-text  ">
                {" "}
                one can choose from{" "}
              </span>
            </h1>

            <p className="mt-3">
              International Toll Free Number – Digitorzo helps in
              providing quality connectivity which indeed helps to get connected
              with the customers across the globe. We provide international
              numbers for U.S.A , UK, Australia, Canada, Africa , Germany and so
              forth. We ensure that HD call quality is maintained and this
              service are provided are free of cost. Our international toll
              free numbers can be put in use swiftly. Digitorzo host
              your international toll free service in the cloud and this will
              help in improving the business and will speed up the process
            </p>
            <br />
          </div>
        </div>
        <div className="container text-center ">
          <div className="row ">
            <div className="col-lg-6 mb-5 ">
              <img
                src={tfn2}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning text-center p-1 mb-4 dip">
                Toll-Free-Numbers
              </div>
              <h1 className="fw-bold hdding home-text text-start mb-3">
                Plus points of Toll Free Number
              </h1>

              <div className="row">
                <div className=" col-lg-12">
                  <ul className="mt-3 ul_li p-0 ">
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; NO additional maintenance.
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp;Calls get recorded automatically and it will be
                      there for 30 days.
                    </li>
                    <li className="text-start pt-3 one-line">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>
                      &nbsp; Call routing can be inactive as per the requirement
                      – Smart ACD, Hosted Ivr, Queue Management.
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; Integration with Crm, free step up and installation
                      charges.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="py-3">
            Vanity Numbers – plays an important role in creating the brand
            awareness as it comes handy because the customer will not be
            required to remember the phone number any more, now when the
            customer calls us they will be required to either call out the word
            or the phrase which is associated with the business and yes these
            numbers are free to call which indeed means one will not be asked to
            pay for making the call. Availing these services one can work from
            anywhere and anytime. We have a team of professionals who will be
            there to support in terms of operational issues
          </div>
        </div>
      </section>

      <section className="section-padding ">
        <div className="container-sm text-center ">
          <div className="  cloud-pdh">
            <h1 className=" home-text">Local Numbers-</h1>

            <p className="mt-3">
              location of a business does not matter anymore. Digitorzo Networks
              LLC offers you secure communication platform to reach out even in
              isolated areas. One can stand out in business if they know their
              customers well and it is just possible by communication and in
              order to give that human touch one can avail number of area codes
              in order to set up virtual local presence and in return it will
              undeniably strengthen the bond between clients, workmate and
              customers even when you are far- off
            </p>
          </div>
        </div>
        <div className="container text-center ">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning text-center p-1 mb-4 dip">
                Local Numbers -
              </div>
              <h1 className="fw-bold hdding home-text text-start mb-3">
                Beneficiary traits of Vanity Numbers
              </h1>

              <div className="row">
                <div className=" col-lg-12">
                  <ul className="mt-3 ul_li p-0">
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; We provide vanity numbers for U.S, Uk, Australia,
                      Germany and many other countries.
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; It comes with call forwarding, routing and
                      distribution attributes.
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>
                      &nbsp; Welcome message for the customers.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-5 ">
              <img
                src={tfn3}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding sec3color">
        <div className="container-sm text-center "></div>
        <div className="container text-center my-5">
          <div className="row ">
            <div className="col-lg-6 mb-5 ">
              <img
                src={tfn4}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning text-center p-1 mb-4 dip">
                Key features-
              </div>
              <h1 className="fw-bold hdding home-text text-start mb-3">
                Key features of Local Number system with Digitorzo.
              </h1>

              <div className="row">
                <div className=" col-lg-12">
                  <ul className="mt-3 ul_li ">
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; Make it easier to reach you by listing one
                      all-purpose number
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; Use any mechanism in order to display local number
                      as outbound caller ID.
                    </li>
                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>
                      &nbsp; One can connect any employee from any location so
                      that the process can be streamlined
                    </li>
                    <li className="text-start pt-3 one-lin">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; Once the call has been landed, if need be route the
                      same to any location.
                    </li>

                    <li className="text-start pt-3">
                      {" "}
                      <img
                        src={Pict}
                        alt="check"
                        className="width-20 pl-2"
                      ></img>{" "}
                      &nbsp; Can use text and voice messages as well so that the
                      information can be imparted.
                    </li>
                  </ul>
                </div>
                <div className="col-lg-6 "></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding ">
        <div className="container-sm  ">
          <div className="  cloud-pdh">
            <h1 className=" home-text">
              Why Choose Digitorzo AS YOUR 800
              <span className="text-success  home-text  ">
                Toll Free Numbers Provider
              </span>
            </h1>

            <p className="mt-3">
              Digitorzo's toll free number provides economical and
              diverse functions to their valued customers to call free of cost
              across the globe and around the clock. We make sure that support
              is provided when the need be regardless of any unforeseen
              circumstances. Digitorzo helps you to customize the
              service as per the business requirement and we emphasize on making
              it user friendly . Digitorzo allows its customer,
              workmate and clients to communicate by their preferred means via
              text, voice, chat and email as well
            </p>
          </div>
        </div>
      </section>
      <section className=" section-padding   ">
        <div className=" container">
          <div className="row ">
            <div className="col-lg-6 mt-5  ">
              {/* <div className="sec3color"> */}
              <h1 className="fw-bold home-text text-start mb-3">
                Noteworthy features which you can use to make your business
                worthier are listed
              </h1>

              <ul className="mt-3 ul_li ">
                <li className="text-start pt-3">
                  {" "}
                  <img
                    src={Pict}
                    alt="check"
                    className="width-20 pl-2"
                  ></img>{" "}
                  &nbsp; No additional cost
                </li>
                <li className="text-start pt-3">
                  {" "}
                  <img
                    src={Pict}
                    alt="check"
                    className="width-20 pl-2"
                  ></img>{" "}
                  &nbsp; Numerous Calling Plans.
                </li>
                <li className="text-start pt-3">
                  {" "}
                  <img src={Pict} alt="check" className="width-20 pl-2"></img>
                  &nbsp; Calls can be routed to mobile as well
                </li>
                <li className="text-start pt-3">
                  {" "}
                  <img
                    src={Pict}
                    alt="check"
                    className="width-20 pl-2"
                  ></img>{" "}
                  &nbsp; Payment can be made only for the services used.
                </li>
                <li className="text-start pt-3">
                  {" "}
                  <img
                    src={Pict}
                    alt="check"
                    className="width-20 pl-2"
                  ></img>{" "}
                  &nbsp; Steadfast team of professional who will assist you by
                  24*7.
                </li>
              </ul>
            </div>

            <div className="col-lg-6  text-start">
              <div className="">
                <img
                  src={tfn5}
                  className="rounded float-star max-width "
                  alt="inbond call"
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default Tollfreenumber;
