import React from "react";
import { HomeBanner, Hosted, Pict } from "../../assets";
import Form from "react-bootstrap/Form";
import Button from "@material-ui/core/Button";
import { FaBeer } from "react-icons/fa";

const TC = () => {
  return (
    <div>
      <div className="containe bg-themebg">
        <div className="container">
          <div className="section-padding text-center">
            <h1 class="home-text py-2">
              Terms <span class="span-color"> Conditions </span>
            </h1>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="section-padding">
          <h2 className="home-text py-2">
            <span class="span-color f-21">Limited License</span>
          </h2>
          <p className="f-18">
            Name of the company grants you a limited License to access and make
            personal as well commercial use of website along with the content
            displayed on the same. Any other redistribution, retransmission,
            copying or publication of any content from this site is completely
            restrain without the written permission of Name of the company. Our
            grant of this limited license does not include
          </p>
          <br />
          <div className="mt-1">
            <ul className="p-0">
              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Any right of commercial use or resale of the site or any
                related content
              </li>
              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Collection and the use of any service or product listings
                as well descriptions
              </li>
              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Making rehashed use of site ant its content
              </li>
              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Use of any data mining,robots,wanderers, scrapers or
                similar data gathering and tearing out methods
              </li>
            </ul>
          </div>
          <h2 className="home-text py-2">
            <span class="span-color f-21">Additional Agreements </span>
          </h2>
          <p className="f-18">
            As the site performs number of functions which even includes
            registration and access to Name of the company Plan services and
            your use of the site is also governed by other agreements, the terms
            and conditions of those agreements in addition to terms set forth
            herein. In case if the site terms contradict with the specific
            agreement for the use of Name of the company’s plan services then
            agreement shall control.
          </p>
          <br />
          <h2 className="home-text py-2">
            <span class="span-color f-21"> Copyright</span>
          </h2>
          <p className="f-18">
            All the content, copyrighted materials, copyrightable materials on
            this site, including, without limitation, scripts, codes, icons,
            buttons scripts, designs, text, graphics, images, pictures, sound
            files and other files, page layout, page headers and the selection
            and arrangement thereof are copyright. Name of the company and
            associated with ALL RIGHTS RESERVED, however as stated herein,the
            Materials may not be copied, reproduced, distributed, republished
            downloaded, displayed, posted or transmitted in any form or by any
            means including but limited to electronic, mechanical, photocopying,
            recording or otherwise without the prior written permission of Name
            of the company or affiliated copyright owner
          </p>
          <br />
          <h2 className="home-text py-2">
            <span class="span-color f-21"> Trademarks and Service Marks</span>
          </h2>
          <p className="f-18">
            All marks and logos present on the site are either trademark,
            service marks or registered trademark of Name of the company or even
            its affiliates. It is important that these trademarks, service mark
            and registered trademarks may not be copied, imitated or even used,
            as a whole or in part without the written permission of Name of the
            company or even the affiliates. Name of the company or its
            associated affiliates trademarks, service marks may not be used in
            any way that will probably cause confusion among customers or in any
            other way that discredits Name of the company or its affiliates.
            Other trademarks, service marks, registered trademarks, product and
            service names and company names or logo appearing on the site which
            are not belonging to name of the company or its affiliates are the
            property of their owners who might not be affiliated with, connected
            to or promoted by Name of the company
          </p>
          <br />
          <h2 className="home-text py-2">
            <span class="span-color f-21">Framing and Linking</span>
          </h2>
          <p className="f-18">
            You might not encompass, frame or otherwise use any Name of the
            company or Name of the company affiliate trademark, logo, design,
            image, page layout or other proprietary information which is on the
            website without the prior written permission of Name of the company
            or it associated affiliates. See under section of Copyright and
            under the section of Trademarks and Service Marks for the further
            information Subject to the restriction in these website terms of use
            without limitation also mentioned in section Limited License, Name
            of the company give you the limited, comprehensive right to create a
            hypertext link to the homepage of this site as long as your link
            does not show Name of the company or its affiliates or any of the
            services in a way which is not correct, misleading or otherwise in a
            vicious manner. In case you do not use a Name of the company or name
            of the company’s affiliate design logo or other graphic,trademark,
            registered trademark to link to this site without the written
            permission of Name of the company. Name of the company saves the
            right at all time in its sole discretion so that your rights can be
            terminated to create a link to the homepage site.
          </p>
          <br />
          <h2 className="home-text py-2">
            <span class="span-color f-21">Third Party Links</span>
          </h2>
          <p className="f-18">
            At times you may come across links to this site available on third-
            party websites. Name of the company may also display links of the
            third party on their websites however these links are provided to
            you for the convenience. Name of the company in no way indicate
            affiliation, endorsement or acquiring of any linked website, of the
            owner or operator of a site or rather any content shown up on the
            site. Name of the company does in no way control any such third
            party websites. Name of the company takes no responsibility for and
            makes no claim for the quality, content or reliability of third
            party websites or services which can be accessed by link from the
            website or the other way around third party websites linked to this
            site. When one leaves the site with the help of the link one should
            now that these websites term of use and Name of the company’s
            Privacy notice, one can also check the section below of Privacy no
            longer can control your use or access to whichever website you may
            want to view hence it becomes all the more essential that one should
            review the terms of use as well the privacy practices and procedures
            of that particular website.
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21"> International Use</span>
          </h2>
          <p className="f-18">
            Name of the company makes on description that materials on its sites
            are apt or available to be used in locations outside in United
            Kingdom and even accessing them from territories where their content
            are illegal is prohibited, hence you agree to abide by all
            applicable domestic and foreign laws, rules and regulations
            regarding the transmission of technical data exported from or
            imported to the United Kingdom or the country in which you reside
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21"> Privacy</span>
          </h2>
          <p className="f-18">
            Name of the company Privacy Notice will explain in details how Name
            of the company will handle information, it will also include
            information that may be obtained when the site will be accessed. The
            Privacy Notice is available at Privacy-Policy
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21"> Copyright Infringement</span>
          </h2>
          <p className="f-18">
            One or more Name of the company Services might allow third parties
            to view, upload, transmit, send or receive copyrighted materials.
            Name of the company has no obligation to and does not review such
            content for the purpose of determining copyright infringement,
            however Name of the company has the right to terminate access to its
            services if a user infringes on others copyrights and will in
            appropriate circumstances, terminate access to services if Name of
            the company determines that one is a repeat infringer If you firmly
            believe that your copyright is being infringed through any content
            on the site or even a part of Name of the company service, one
            should notify Name of the company in writing at complete email
            address along with a copy to be send at Physical Address Any notice
            of claimed infringement must be able to identify the copyrighted
            work that is claimed or the same have been infringed and must
            include a detailed description of the content that is claimed to be
            infringing or it is subject to infringing activity. The notice of
            claimed infringement should have enough information in order to
            permit Name of the company to locate any infringing content and must
            also include you and the complaining party’s contact information
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21">
              {" "}
              Governing Law and Dispute Resolution
            </span>
          </h2>
          <p className="f-18">
            This Agreement and your use of the Name of the company must be
            governed and constructed under the laws of have to mention the
            country where the company will be registered, in the event of
            dispute, claim, question or disagreement between you and the Name of
            the company, you and the Name of the company shall first strive to
            settle the dispute, claim, question or disagreement informally, at
            this point you and an authorized member of the Name of the company’s
            legal department should consult and negotiate with one another in
            good faith and one you should be able to understand their mutual
            interests and should endeavor to reach on a solution which is
            justified and satisfactory to both parties. Neither you nor name of
            the company should file any dispute in any court, administrative or
            arbitral or other adjudicative body before engaging in any such
            consultations and negotiations or following our dispute resolution
            policy which is mentioned in our code of practice
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21"> Severability</span>
          </h2>
          <p className="f-18">
            If any part of these terms and conditions is set to be unenforceable
            under applicable law that part shall be constructed as nearly as
            possible to reflect the original intentions of the parties and then
            the remaining terms set forth herein shall continue to have full
            force and effect
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21">
              {" "}
              Disclaimers and Limitation of Liability
            </span>
          </h2>
          <p className="f-18">
            Access to this site is provided as is and as available and Name of
            the company does not guarantee that the site is or will be accurate,
            complete or free of computer viruses and other harmful components.
            Name of the company gives nor warranty, express or implied,
            including but not limited to the implied warranties of satisfactory
            quality or fitness for a particular purpose even if he warranty
            arises under provisions of any Law have to mention the country in
            UKor any other country. Permitted by law, no advice or information
            even it is oral or written obtained by you from Name of the company
            its employees through or from the Services shall create any warranty
            not clearly stated in these terms and conditions. Nothing at all in
            this agreement excludes Name of the company’s liability for death or
            personal injury caused by Name of the company’s negligence or in
            other words negligence of Name of the company’s employee or agents
            or even the fraudulent misrepresentation Full extent permitted by
            law in no event shall Name of the company be liable to you or third
            party for any direct, indirect or even the consequential damages
            arising under contract, warranty, error or any other theory of
            liability. Name of the company’s total liability for any slightest
            or all damages, no matter what is the form of the action, it shall
            be limited and capped to the total amount of fess paid in the
            calendar year during which course of action rises or claims first
            occurred. The limitation of liability depends on the assignment of
            the risk between the parties. The limitations mentioned in this
            section will survive and apply in any given circumstances.
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21">Third –Party Rights</span>
          </h2>
          <p className="f-18">
            The furnishing of the Disclaimers and limitation of Liability
            section above are the benefit of Name of the company as well as its
            affiliaties as its officers, directors, employees, agents,
            Licensors, contractors, suppliers as well any third party
            information providers to the Site. Each one of these individuals or
            entities shall have the right to claim and impose those provisions
            directly against you on its behalf
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21">
              Enforceability and Admissibility
            </span>
          </h2>
          <p className="f-18">
            This electronic document as well other documents, policies and
            guidelines incorporated herein will be
          </p>
          <div className="mt-1">
            <ul className="p-0">
              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Considered for all purposes to be a “writing” or “in
                writing” and to act in accordance with all statutory,
                contractual and other legal requirements for a writing
              </li>
              <li>
                <img src={Pict} alt="check" className="width-20 pl-2"></img>
                &nbsp; Legally enforceable as a signed writing as against the
                parties subject to the electronic documents
              </li>
            </ul>
          </div>
          <p className="f-18">
          Considered “original” when it will be printed from electronic records
          confirmed and maintained in the ordinary course of business Electronic
          documents used as evidence in any judicial, arbitration, mediation or
          administrative proceedings will, if established and maintained in the
          ordinary course of business, be allowed to the same extent as business
          records in written form that are similarly established and maintained.
          </p>
          <h2 className="home-text py-2">
            <span class="span-color f-21">
              Waiver, Modification and Assignment
            </span>
          </h2>
          <p className="f-18">
            If the Name of the company fails to insist upon or impose strict
            performance of any provision of this Agreement will not be set up as
            a waiver of any provision or right thereof. Neither the course of
            conduct between Name of the company and the one who uses the site
            nor trade practices shall act as a modification of any provision of
            this Agreement. Name of the company may allocate its rights and
            duties under this Agreement to any party at any time without any
            notice to you.
          </p>
        </div>
      </div>
    </div>
  );
};
export default TC;
