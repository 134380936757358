import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../pages/home";
import About from "../pages/about";
import Services from "../pages/services";
import CallForward from "../pages/CallFrowording";
import Hostedpbx from "../pages/Hostedpbx";
import Callcentersolution from "../pages/Callcentersolution";
import VoIPTelephony from "../pages/Voipteliphony";
import TollFreeNumber from "../pages/TollFreNumber";
import siptrunking from "../pages/SIPTrunking";
import CallRecording from "../pages/CallRecording";
import Callblast from "../pages/CallBlast";
import Callwaiting from "../pages/CallWaiting";
import CallConferencing  from "../pages/Callconferencing";
import  CallScreenAnnounce  from "../pages/CallScreenAnnounce";
import  ACD  from "../pages/ACB";
import Donotdisturb from "../pages/DND";
import Musiconhold from "../pages/MusicOnHold";
import dailbyname from "../pages/DailByName";
import CustomizedMessages from "../pages/CustomizedMessages";
import callqueuemanagement from "../pages/Callqueuemanagement";
import Voicemail from "../pages/VoiceMail";
import Internetfax from "../pages/InternetFex";
import Hostedivr from "../pages/HostedIVR";
// import UnifiedCommuni from "../pages/UnifiedCommuni";
import softphone from "../pages/Softphone";
import Aboutus from "../pages/about-us";
import Support from "../pages/Support";
import Policy from "../pages/Privacy";
import TC from "../pages/TC";

const Routing = () => {
  return (
    <Switch>
      <Route path="/" exact={true} component={Home} />
      <Route path="/hosted-pbx" exact={true} component={Hostedpbx} />
      <Route path="/call-center-solutions" component={Callcentersolution} />
      <Route path="/contact-us" component={About} />
      <Route path="/about-us" component={Aboutus} />
      <Route path="/services" component={Services} />
      <Route path="/call-forward" exact={true} component={CallForward} />
      <Route path="/voip-telephony" exact={true} component={VoIPTelephony} />
      <Route path="/toll-free-number" exact={true} component={TollFreeNumber} />
      <Route path="/sip-trunking" exact={true} component={siptrunking} />
      <Route path="/voip-telephony" exact={true} component={VoIPTelephony} />
      <Route path="/call-balsting" exact={true} component={Callblast} />
      <Route path="/call-recording" exact={true} component={CallRecording} />
      <Route path="/call-waiting" exact={true} component={Callwaiting} />
      <Route path="/call-conferencing" exact={true} component={CallConferencing} />
      <Route path="/call-Screen-announce" exact={true} component={CallScreenAnnounce} />
      <Route path="/acd" exact={true} component={ACD} />
      <Route path="/dnd" exact={true} component={Donotdisturb} />
      <Route path="/music-on-hold" exact={true} component={Musiconhold} />
      <Route path="/dail-by-name" exact={true} component={dailbyname} />
      <Route path="/customized-messages" exact={true} component={CustomizedMessages} />
      <Route path="/call-queue-management" exact={true} component={callqueuemanagement} />
      <Route path="/voice-mail" exact={true} component={Voicemail} />
      <Route path="/internet-fax" exact={true} component={Internetfax} />
      <Route path="/hosted-ivr" exact={true} component={Hostedivr} />
      {/* <Route path="/unified-communications" exact={true} component={UnifiedCommuni} /> */}
      <Route path="/softphone" exact={true} component={softphone} />
      <Route path="/support" exact={true} component={Support} />
      <Route path="/privacy-policy" exact={true} component={Policy} />
      <Route path="/terms-and-conditions" exact={true} component={TC} />



    </Switch>
  );
};

export default Routing;
