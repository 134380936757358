import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "styletron-react";
import "styletron-engine-atomic";
import { StyleReset } from 'atomize';


ReactDOM.render(
  <React.StrictMode>
    {/* <BrowserRouter basename="/demo"> */}
    <BrowserRouter>
      <StyleReset />
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
